<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
    fullscreen
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
          color="accent"
          dark
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.edit') }} {{ item.name }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-row>
                <v-col cols="4">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        ¿Es entrenamiento en aguas abiertas?
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="¿Es entrenamiento en aguas abiertas?"
                          rules=""
                        >
                          <v-switch
                            v-model="item.is_open_seas"
                            class="pl-5"
                            placeholder="¿Es entrenamiento en aguas abiertas?"
                            :label="item.is_open_seas ? $t('app.general.yes') : $t('app.general.no')"
                          />
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Nombre
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Nombre"
                          rules="required"
                        >
                          <v-text-field
                            v-model="item.name"
                            class="pl-5"
                            placeholder="Nombre"
                          />
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Calentamientos

                    <v-btn
                      small
                      outlined
                      @click="openWarmUpsAdd(item)"
                    >
                      <v-icon left>mdi-plus-circle</v-icon>

                      {{ $t('app.buttons.add') }}
                    </v-btn>
                  </v-list-item-title>

                  <v-data-table
                    :headers="warmUpsHeaders"
                    :items="item.warm_ups"
                    :items-per-page="10"
                    :custom-filter="customFilter"
                  >
                    <template v-slot:item.overview="{ item }">
                      <span v-html="item.overview"></span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-col class="d-flex justify-end">
                        <v-btn
                          icon
                          @click="openWarmUpsEdit(item)"
                        >
                          <v-icon>
                            mdi-pencil
                          </v-icon>
                        </v-btn>

                        <v-btn
                          icon
                          @click="openWarmUpsDelete(item)"
                        >
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </template>
                  </v-data-table>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('app.main-series.name') }}

                    <v-btn
                      small
                      outlined
                      @click="openMainSeriesAdd(item)"
                    >
                      <v-icon left>mdi-plus-circle</v-icon>

                      {{ $t('app.buttons.add') }}
                    </v-btn>
                  </v-list-item-title>

                  <v-data-table
                    :headers="mainSeriesHeaders"
                    :items="item.main_series"
                    :items-per-page="10"
                    :custom-filter="customFilter"
                  >
                    <template v-slot:item.overview="{ item }">
                      <span v-html="item.overview"></span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-col class="d-flex justify-end">
                        <v-btn
                          icon
                          @click="openMainSeriesEdit(item)"
                        >
                          <v-icon>
                            mdi-pencil
                          </v-icon>
                        </v-btn>

                        <v-btn
                          icon
                          @click="openMainSeriesDelete(item)"
                        >
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </template>
                  </v-data-table>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Secciones Finales

                    <v-btn
                      small
                      outlined
                      @click="openFinalSectionAdd(item)"
                    >
                      <v-icon left>mdi-plus-circle</v-icon>

                      {{ $t('app.buttons.add') }}
                    </v-btn>
                  </v-list-item-title>

                  <v-data-table
                    :headers="finalSectionHeaders"
                    :items="item.final_sections"
                    :items-per-page="10"
                    :custom-filter="customFilter"
                  >
                    <template v-slot:item.overview="{ item }">
                      <span v-html="item.overview"></span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-col class="d-flex justify-end">
                        <v-btn
                          icon
                          @click="openFinalSectionEdit(item)"
                        >
                          <v-icon>
                            mdi-pencil
                          </v-icon>
                        </v-btn>

                        <v-btn
                          icon
                          @click="openFinalSectionDelete(item)"
                        >
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </template>
                  </v-data-table>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>

    <warm-ups-add></warm-ups-add>
    <warm-ups-edit></warm-ups-edit>
    <warm-ups-delete></warm-ups-delete>

    <main-series-add></main-series-add>
    <main-series-edit></main-series-edit>
    <main-series-delete></main-series-delete>

    <final-section-add></final-section-add>
    <final-section-edit></final-section-edit>
    <final-section-delete></final-section-delete>
  </v-dialog>
</template>

<script>
  import customFilter from '@/plugins/customFilter'

  export default {
    name: 'DayEdit',
    mixins: [customFilter],
    components: {
      WarmUpsAdd: () => import("../warm_ups/WarmUpAdd"),
      WarmUpsEdit: () => import("../warm_ups/WarmUpEdit"),
      WarmUpsDelete: () => import("../warm_ups/WarmUpDelete"),

      MainSeriesAdd: () => import("../main_series/MainSeriesAdd"),
      MainSeriesEdit: () => import("../main_series/MainSeriesEdit"),
      MainSeriesDelete: () => import("../main_series/MainSeriesDelete"),

      FinalSectionAdd: () => import("../final_section/FinalSectionAdd"),
      FinalSectionEdit: () => import("../final_section/FinalSectionEdit"),
      FinalSectionDelete: () => import("../final_section/FinalSectionDelete"),
    },
    data () {
      return {
        dialog: false,
        item: {
          name: "",
          warm_ups: [],
          main_series: [],
          final_sections: [],
          is_open_seas: false,
        },
        warmUpsHeaders: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: "Intensidad",
            value: "intensity"
          },
          {
            text: "Repeticiones",
            value: "replays"
          },
          {
            text: "Duración",
            value: "duration"
          },
          {
            text: "Descanso",
            value: "rest_interval"
          },
          {
            text: this.$t('app.headers.description'),
            value: 'overview',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        mainSeriesHeaders: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: "Intensidad",
            value: "intensity"
          },
          {
            text: "Repeticiones",
            value: "replays"
          },
          {
            text: "Duración",
            value: "duration"
          },
          {
            text: "Descanso",
            value: "rest_interval"
          },
          {
            text: "Pausa",
            value: "pause"
          },
          {
            text: this.$t('app.headers.description'),
            value: 'overview',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        finalSectionHeaders: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: "Intensidad",
            value: "intensity"
          },
          {
            text: "Repeticiones",
            value: "replays"
          },
          {
            text: "Duración",
            value: "duration"
          },
          {
            text: this.$t('app.headers.description'),
            value: 'overview',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        week: {},
      }
    },
    mounted () {
      EventBus.$on('days-edit',  (item) => {
        this.item   = item.day
        this.week   = item.week
        this.dialog = true
      })

      EventBus.$on('reload-items-warm-ups-add', (item) => {
        this.item = item
      })

      EventBus.$on('reload-items-main-series-add', (item) => {
        this.item = item
      })

      EventBus.$on('reload-items-final-section-add', (item) => {
        this.item = item
      })

      EventBus.$on('reload-items-main-series-edit', (item) => {
        this.item.main_series = this.item.main_series.map(m => (m.id == item.id ? item : m))
      })

      EventBus.$on('reload-items-warm-ups-edit', (item) => {
        this.item.warm_ups = this.item.warm_ups.map(m => (m.id == item.id ? item : m))
      })

      EventBus.$on('reload-items-final-section-edit', (item) => {
        this.item.final_sections = this.item.final_sections.map(m => (m.id == item.id ? item : m))
      })

      EventBus.$on('reload-items-main-series-delete', (item) => {
        this.item.main_series = this.item.main_series.filter(m => m.id != item.id)
      })

      EventBus.$on('reload-items-warm-ups-delete', (item) => {
        this.item.warm_ups = this.item.warm_ups.filter(m => m.id != item.id)
      })

      EventBus.$on('reload-items-final-section-delete', (item) => {
        this.item.final_sections = this.item.final_sections.filter(m => m.id != item.id)
      })
    },
    methods: {
      openWarmUpsAdd(day) {
        EventBus.$emit('warm-ups-add', day)
      },
      openWarmUpsEdit(item) {
        EventBus.$emit("warm-ups-edit", item)
      },
      openWarmUpsDelete(item) {
        EventBus.$emit("warm-ups-delete", item)
      },

      openMainSeriesAdd(day) {
        EventBus.$emit('main-series-add', day)
      },
      openMainSeriesEdit(item) {
        EventBus.$emit("main-series-edit", item)
      },
      openMainSeriesDelete(item) {
        EventBus.$emit("main-series-delete", item)
      },

      openFinalSectionAdd(day) {
        EventBus.$emit('final-section-add', day)
      },
      openFinalSectionEdit(item) {
        EventBus.$emit("final-section-edit", item)
      },
      openFinalSectionDelete(item) {
        EventBus.$emit("final-section-delete", item)
      },

      async saveItem () {
        this.$refs.observer.validate()
          .then(async success => {
            if (!success) return

            this.toggleLoader()

            await this.$http.put(route(`days/${this.item.id}`), {
              data: this.item
            })
              .then(response => {
                if (response.body.data) {
                  //this.processSuccess(response)
                  let day = response.body.data
                  this.week.days = this.week.days.map(d => d.id == day.id ? day : d)
                  this.dialog = false
                  EventBus.$emit('reload-items-days-edit', this.week)
                } else {
                  this.processError(response)
                }
              }, error => {
                this.processError(error)
              })

            this.toggleLoader()
          })
      },
    },
  }
</script>

<style scoped>

</style>
